//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis, FullModelView } from '@/components'
import {
  documentFormAddApi,
  documentFormEditApi
} from '@/api/modular/fileStream/documentHead'
import { mapState } from 'vuex'
import editor from './editor'
export default {
  name: 'StepsForm',
  components: {
    Ellipsis,
    FullModelView,
    editor
  },
  computed: {
    ...mapState({
      visible: state => state.bill.postMsg
    })
  },
  data() {
    return {
      title: '新增',
      type:'add',
      subTitle: '紅頭模板',
      content: '', // 富文本內容
      confirmLoading: false,// 提交loading
      form: {
        id:'',
        name: '',
        content: ''
      },
      rules: {
        name: [
          { required: true, message: '請輸入名稱', trigger: ['change', 'blur'] },
        ],
        content: [
          { required: true, message: '請輸入模板', trigger: ['change', 'blur'] },
        ]
      },
    }
  },
  beforeDestroy() {
    this.visible && this.$store.commit('bill/setPostMsg', false)
  },
  methods: {
    open({ type, data }) {
      this.form = Object.assign(this.form,data)
      this.type = type
      if (type === 'edit') {
        this.title = '編輯'
      } else if (type === 'show') {
        this.title = '詳情'
      } else {
        this.title = '新增'
        //this.$refs.MyForm.clearMessage()
        this.resetContent()
      }
      this.$store.commit('bill/setPostMsg', true)
      this.$nextTick(()=>{
        this.$refs.ruleForm.clearValidate()
      })
    },
    close() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('refresh')
    },
    handleCancel() {
      this.$store.commit('bill/setPostMsg', false)
      this.$emit('refresh')
    },
    resetContent() {
      this.form =Object.assign({},{
        id:'',
        name: '',
        content: ''
      })
    },
    submitForm() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(this.type == 'add'){
            this.documentFormAddFun()
          }else if(this.type == 'edit'){
            this.documentFormEditFun()
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    documentFormAddFun() {
      this.confirmLoading = true
      documentFormAddApi({...this.form}).then((res) => {
        if (res.success) {
          this.$message.success(res.message)
          this.handleCancel()
        } else {
          this.$message.error(res.message)
        }
        this.confirmLoading = false
      }).finally((res) => {
        this.confirmLoading = false
      })
    },
    // 編輯
    documentFormEditFun() {
      this.confirmLoading = true
      documentFormEditApi({...this.form}).then((res) => {
        if (res.success) {
          this.$message.success(res.message)
          this.handleCancel()
        } else {
          this.$message.error(res.message)
        }
        this.confirmLoading = false
      }).finally((res) => {
        this.confirmLoading = false
      })
    }
  },
  
}
