import { axios } from '@/utils/request'


/**
 * 獲取紅頭文件列表  分頁
 *
 * @author xyl
 * @date 2022-06-10 18:16:00
 */
 export function documentFormPageApi (parameter) {
    return axios({
      url: '/document/form/page',
      method: 'get',
      params: parameter
    })
}

/**
 * 拷貝系統模板
 * @param {*} id
 * @returns
 */
export const copySys = id => axios({
  url: `/document/form/copy/${id}`,
  method: 'get'
})

/**
 * 合成PDF
 * @param {*} data
 * @returns
 */
export const toPdf = data => axios({
  url: '/document/form/toPdf',
  method: 'post',
  data
})

/**
 * 新增模板
 *
 */
 export function documentFormAddApi (parameter) {
    return axios({
      url: '/document/form/add',
      method: 'post',
      data: parameter
    })
}

/**
 * 更新模板
 *
 */
 export function documentFormEditApi (parameter) {
  return axios({
    url: '/document/form/edit',
    method: 'post',
    data: parameter
  })
}

/**
 *  刪除模板
 *
 */
 export function documentFormDeleteApi (parameter) {
    return axios({
      url: '/document/form/delete',
      method: 'post',
      data: parameter
    })
}

/**
 *  獲取某一模板
 *
 */
 export function documentFormDetailApi (parameter) {
    return axios({
      url: '/document/form/detail/'+parameter.id,
      method: 'get',
      data: parameter
    })
}