//
//
//
//
//
//
//

import { serverUrl } from '@/mixins/config.js'
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  components: { VueUeditorWrap },
  data() {
    return {
      msg: '',
    }
  },
  props: {
    content: {
      type: String,
      default: '',
    },
    isOnlyRead: {
      type: Boolean,
      default: false,
    },
  },
  // props: ['content', 'isOnlyRead'],
  watch: {
    msg(newval, oldval) {
      this.$emit('update:content', newval)
    },
    content: {
      handler(newval) {
        // console.log(this.content)
        if (newval) this.msg = newval
      },
      immediate: true,
    },
  },
  created() {
    console.log('editor 初始化')
    this.init_editor()
  },
  methods: {
    init_editor() {
      // 更多 UEditor 配置，參考 http://fex.baidu.com/ueditor/#start-config
      this.editorConfig = {
        // 訪問 UEditor 靜態資源的根路徑，可參考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UEditor/',
        // 服務端接口（這個地址是我為了方便各位體驗文件上傳功能搭建的臨時接口，請勿在生產環境使用！！！）
        //serverUrl: '//192.168.2.92:81/api/sysFileInfo/upload',//'//ueditor.szcloudplus.com/cos',////`http://192.168.2.93:8080/api/sysFileInfo/upload`
        serverUrl: `//${serverUrl}:8080/ueditor/server`,
        initialFrameHeight: 500,
        autoHeightEnabled: false,
        readonly: this.isOnlyRead,
      }
    },
  },
}
